export const xhr = {
    mounted() {
        // console.log(window)
        var client = undefined;

        function waitForYm(ymCounterNum, callback, interval) {
            if (!callback) return;
            if (!ymCounterNum) {
                let metrikaObj = (window.Ya && (window.Ya.Metrika || window.Ya.Metrika2)) || null;
                ymCounterNum = (metrikaObj && metrikaObj.counters && (metrikaObj.counters() || [0])[0].id) || 0;
            }
            let ymCounterObj = window['yaCounter' + ymCounterNum] || null;
            if (ymCounterObj) return (callback(ymCounterObj, ymCounterNum), undefined);
            setTimeout(function () {
                waitForYm(ymCounterNum, callback, interval);
            }, interval || 250);
        }

        function getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        waitForYm(null,  (counter, counterNum) => {
            // console.log(counterNum);
            var counterName = 'yaCounter' + counterNum;
            if (typeof eval(counterName) !== undefined) {
                client = eval(counterName).getClientID();
            } else if (typeof ym !== undefined) {
                ym(counterNum, 'getClientID', function (clientID) {
                    client = clientID;
                });
            }

            if (client === undefined || client === null) {
                client = getCookie('_ym_uid');
            }

            if (client !== undefined) {
                client = counterNum + '_' + client;
                this.client = client;
                this.xhrDetect(client);
            }
        });
    },
    methods: {

        xhrDetect(client) {
            let urlTo = this.url;
            let realUrl;

            function getUrlVars(url) {
                let hash;
                let myJson = {};
                let hashes = url.slice(url.indexOf('?') + 1).split('&');
                for (let i = 0; i < hashes.length; i++) {
                    hash = hashes[i].split('=');
                    myJson[hash[0]] = decodeURIComponent(hash[1]);
                }
                return myJson;
            }

            let xhr = window.XMLHttpRequest.prototype.open;
            let xhrSend = window.XMLHttpRequest.prototype.send;

            window.XMLHttpRequest.prototype.open = function () {
                realUrl = arguments[1];
                return xhr.apply(this, [].slice.call(arguments));
            };

            window.XMLHttpRequest.prototype.send = function (data) {
                if (realUrl !== urlTo && !realUrl.includes('mc.yandex.ru')) {
                    let xhr = new XMLHttpRequest();
                    xhr.open('POST', urlTo, true);
                    if (data !== null) {
                        if (data.includes('&') && data.includes('=')) {
                            let urlData = getUrlVars(data);
                            urlData.clientId = client;
                            // console.log(client, urlData);
                            xhr.send(JSON.stringify(urlData));
                        } else {
                            let result = data.replace('}', ', "clientId": "' + client + '"}');
                            // console.log(client, result);
                            xhr.send(result);
                        }
                    }
                }
                return xhrSend.apply(this, [].slice.call(arguments));
            };
        }
    }
};





