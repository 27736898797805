export const submit = {
    mounted() {
        let formCatch = document.querySelectorAll("form");
        for (let form of formCatch) {
            form.onsubmit = this.submitted.bind(form);
        }
    },

    methods: {
        submitted(event) {
            let url = this.url;

            let formData = new FormData(event.target);
            formData.append('clientId', this.client);
            // console.log(formData, this.client);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.send(formData);
        },
    }
};