<template>
    <div id="app"></div>
</template>

<script>
    import {submit} from './mixins/detect/submit'
    import {xhr} from './mixins/detect/xhr'

    export default {
        mixins: [submit, xhr],
        data() {
          return {
              url: 'https://bread.burno.tech/take-form',
              // url: 'http://bread/take-form',
              // url: 'http://127.0.0.1:8000/take-form',
              client: null
          }
        },
        name: 'app',
        components: {}
    }
</script>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>
